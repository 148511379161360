import React from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';

import Layout from '../../layouts/index';
import { Container, Content } from '../../components/home/v3/styledComponents';
import MenuBar from '../../components/home/MenuBar';
import Footer from '../../components/home/v3/Footer';
import AdditionalSlots from '../../components/AdditionalSlots';

const getOrderId = (locationSearch = '') => queryString.parse(locationSearch).orderId;
const getSlots = (locationSearch = '') => {
  const slots = [];
  const {
    slot1Text, slot1Date,
    slot2Text, slot2Date,
    slot3Text, slot3Date,
    slot4Text, slot4Date,
    selectedSlot,
  } = queryString.parse(locationSearch);
  if (slot1Text) slots.push({ text: slot1Text, date: slot1Date, isSelected: selectedSlot === '1' });
  if (slot2Text) slots.push({ text: slot2Text, date: slot2Date, isSelected: selectedSlot === '2' });
  if (slot3Text) slots.push({ text: slot3Text, date: slot3Date, isSelected: selectedSlot === '3' });
  if (slot4Text) slots.push({ text: slot4Text, date: slot4Date, isSelected: selectedSlot === '4' });
  return slots;
};

const AdditionalSlotsPage = ({ location }) => (
  <Layout routeSlug="AdditionalSlots">
    <Container>
      <MenuBar />
      <Content>
        <AdditionalSlots
          orderId={getOrderId(location.search)}
          slots={getSlots(location.search)}
        />
      </Content>
      <Footer />
    </Container>
  </Layout>
);

AdditionalSlotsPage.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string,
  }).isRequired,
};

export default AdditionalSlotsPage;
